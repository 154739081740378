.p1 {
  margin: 0px 0px 0px 0px;
  font: 1.2rem 'Helvetica Neue';
}

.p2 {
  margin: 0px 0px 0px 0px;
  font: 1.2rem 'Helvetica Neue';
  min-height: 1.5rem;
}

.s1 {
  font: 1.2rem 'Helvetica Neue';
}
